.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}
.form-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    text-align: left;
}
label {
    margin-right: 10px;
    font-size: small;
}
input {
    padding: 10px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.form-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.form-column {
    display: flex;
    flex-direction: column;
    margin-right: 1em;
}

.form-column label {
    margin-bottom: 0.5em;
}
.heading{
    text-align: center;
}
.search-button-div{
    text-align: center;
}
