/* Assuming you have a parent container to center the spinner */
.parent-container {
    display: flex;
    justify-content: center; /* Horizontally center the spinner */
    align-items: center; /* Vertically center the spinner */
    height: 50vh; /* Adjust this value based on your layout */
}

/* Styling the spinner */
.spinner {
    font-size: 108px;
}